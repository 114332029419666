<template #page-content>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Personal Info</h2>
      <r-third-level-menu />
    </template>
    <template #page-content>
      <div id="personal-information">
        <div v-if="!isLoadingCurrentUser && currentUser != null">
          <EmployeeInfoHeaderPersonal
            :user-id="currentUser.id"
            :profile-picture-url="formatProfilePictureUrl(currentUser)"
            :employee-id="formatEmployeeId(currentUser)"
            :full-name="currentUser.fullName"
            :position-name="formatPositionName(currentUser)"
            :working-status="currentUser.workingStatus"
            :timezone="currentUser.timezone"
            :annualLeave="formatAnnualLeave(currentUser)"
            :remainingAnnualLeave="formatRemaining(currentUser)"
            :reporting-to="formatReportingTo(currentUser)"
          />

          <div class="columns is-multiline">
            <div class="column is-6-desktop is-6-tablet is-12-mobile">
              <GeneralInformation
                :user-id="currentUser.id"
                :birth-day="formatBirthday(currentUser)"
                :address="formatAddress(currentUser)"
                :gender="formatGender(currentUser)"
                :contact-number="formatPhoneNumber(currentUser)"
                :personal-email="formatPersonalEmail(currentUser)"
                :office-email="formatOfficeEmail(currentUser)"
                :religion="formatReligion(currentUser)"
                :blood-type="formatBloodType(currentUser)"
                editable
              />
            </div>
          </div>
        </div>
        <div v-else>Loading</div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { showToast } from '../../../services/util'
import EmployeeInfoHeaderPersonal from '../../../components/EmployeeInfoHeaderPersonal'
import GeneralInformation from '../../../components/GeneralInformation'
export default {
  components: { GeneralInformation, EmployeeInfoHeaderPersonal },
  // components: {},
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      isLoadingCurrentUser: 'user/getIsLoading',
      uploadImageProgress: 'user/getUploadImageProgress',
    }),
  },
  data() {
    return {
      key: 1,
      isModalChangePictureOpen: false,
      imageToBeReplaced: null,
      file: null,
    }
  },
  methods: {
    ...mapActions({
      changeProfileImage: 'user/changeProfileImage',
    }),
    ...mapMutations({
      setProfilePictureUrl: 'user/setProfilePictureUrl',
    }),
    removePickedImage() {
      // reset to original image
      this.imageToBeReplaced = this.formatProfilePictureUrl(this.currentUser)
      this.file = null
    },
    getImage(e) {
      this.file = e

      //*validate size here. Size is in Byte. maximum 2MB
      if (this.file.size > 2048000) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'File size maximum is 2 MB',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } else {
        let reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = (e) => {
          this.imageToBeReplaced = e.target.result
        }
      }
    },

    async uploadProfileImage() {
      if (this.file == null) {
        showToast('Please pick an image', 'is-danger', 'is-bottom')
        return
      }
      try {
        let form = new FormData()
        form.append('user_id', this.currentUser.id)
        form.append('image', this.file)
        await this.changeProfileImage(form)
      } catch (err) {
        this.file = null
        showToast(err.response.data.message, 'is-danger', 'is-bottom')
      }
      this.closeModalChangeProfilePicture()
    },

    openModalChangePicture() {
      this.imageToBeReplaced = this.formatProfilePictureUrl(this.currentUser)
      this.isModalChangePictureOpen = true
    },
    closeModalChangeProfilePicture() {
      this.file = null
      this.isModalChangePictureOpen = false
    },

    formatPhoneNumber(user) {
      if (user?.personalPhone) {
        return user?.personalPhone
      }
      return '-'
    },
    formatPersonalEmail(user) {
      if (user?.personalEmail) {
        return user?.personalEmail
      }
      return '-'
    },
    formatOfficeEmail(user) {
      if (user?.workEmail) {
        return user?.workEmail
      }
      return '-'
    },
    formatBloodType(user) {
      if (user?.bloodType) {
        return user?.bloodType
      }
      return '-'
    },
    formatReligion(user) {
      if (user?.religion) {
        return user?.religion
      }
      return '-'
    },

    formatReportingTo(user) {
      if (user?.employment?.reportingTo) {
        return (
          user?.employment?.reportingTo?.firstName +
          ' ' +
          user?.employment?.reportingTo?.middleName +
          ' ' +
          user?.employment?.reportingTo?.lastName
        )
      }
      return '-'
    },

    formatPositionName(user) {
      if (user?.position) {
        return user?.position.positionName
      }
      return '-'
    },
    formatBirthday(user) {
      if (user?.placeOfBirth) {
        if (user?.dateOfBirth) {
          return user?.placeOfBirth + ', ' + this.parseDate(user?.dateOfBirth)
        }
        return user?.placeOfBirth
      }
      return '-'
    },
    formatAnnualLeave(user) {
      if (user?.employment) {
        return user?.employment?.annualLeave
      }
      return '-'
    },
    formatRemaining(user) {
      if (user?.employment) {
        return user?.employment?.remainingAnnualLeave
      }
      return '-'
    },
    parseDate(timeStamp) {
      return moment(timeStamp).format('DD MMMM YYYY')
    },
    parseDateTime(timeStamp) {
      return moment(timeStamp).format('DD MMM YYYY, hh:mm')
    },
    formatGender(user) {
      if (user?.gender == 'F') {
        return 'Female'
      }
      return 'Male'
    },
    formatProfilePictureUrl(user) {
      if (user?.profilePictureUrl) {
        return user?.profilePictureUrl
      }

      if (user?.gender == 'M') {
        return '/images/default-profile-picture-male.png'
      }

      return '/images/default-profile-picture-female.png'
    },
    formatEmployeeId(user) {
      if (user?.employment?.nik) {
        return user?.employment?.nik
      }
      return '-'
    },
    formatAddress(user) {
      let address = '-'

      if (user?.address.streetName) {
        address = user?.address.streetName
      }
      if (user?.address.district) {
        address += ', ' + user?.address.district
      }
      if (user?.address.village) {
        address += ', ' + user?.address.village
      }
      if (user?.address.city) {
        address += ', ' + user?.address.city
      }
      if (user?.address.province) {
        address += ', ' + user?.address.province
      }
      return address
    },
  },
}
</script>
